import React from 'react';
import Grid from '@material-ui/core/Grid'
import {BooleanInput, required, SelectInput, TextInput} from 'react-admin';

const statusInFlow = [
        {id: 'OFF_PREV_ON_TIME', name: 'OFF'},
        {id: 'CM', name: 'CM'},
        {id: 'MISSING_CARD', name: 'Không thẻ'},
        {id: 'ABSENT_WITH_PERMISSION', name: 'Vắng có phép'},
        {id: 'ABSENT', name: 'Vắng chưa xin phép'},
        {id: 'IN_LATE', name: 'Trễ'},
        {id: 'ON_TIME', name: 'Đúng giờ'},
]

const AuthorizedAttendanceHistoryFields = props => {
    const {record} = props;

    React.useEffect(() => {
    }, []);

    return (
        <React.Fragment>
            <Grid item xs={12} sm={6}>
                <TextInput disabled source="unitId"
                           validate={required()} {...props}/>
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextInput disabled source="customerName"
                           validate={required()} {...props}/>
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextInput disabled source="displaySummaryDate"
                           validate={required()} {...props}/>
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextInput disabled source="unitName"
                           validate={required()} {...props}/>
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextInput disabled source="eventName"
                           validate={required()} {...props}/>
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextInput disabled source="displayTimeIn" {...props}/>
            </Grid>
            <Grid item xs={12} sm={6}>
                <SelectInput source="statusIn"
                             defaultValue={"ABSENT"}
                             validate={required()}
                             choices={statusInFlow} {...props}/>
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextInput source="reason" {...props}/>
            </Grid>
            <Grid item xs={12} sm={6}>
                <BooleanInput source="sendNotify" {...props} />
            </Grid>
        </React.Fragment>
    )
}

export default AuthorizedAttendanceHistoryFields
