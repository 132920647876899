import React, {useState} from 'react';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import {DateInput, ReferenceInput, required, Title} from 'react-admin';
import {ReportForm, schoolYears, TreeCheckboxInput} from '../../components';
import env from '../../env';
import moment from "moment";
import {downloadFilePost} from "../../components/FileDownload";

const TongHopDiemDanh = (props) => {
    const [state, setState] = useState({
        fromDate: new Date(),
        toDate: new Date(),
        unitIds: [],
        isLoading: false
    });

    const excelReport = () => {
        setState({...state, isLoading: true})
        const data = {
            fromDate: moment(state.fromDate).format('YYYY-MM-DD'),
            toDate: moment(state.toDate).format('YYYY-MM-DD'),
            unitIds: state.unitIds
        }
        downloadFilePost(`${env.reportURL}/reports/tong-hop-diem-danh`, 'tong_hop_diem_danh.xlsx', data)
            .then(() => {
                setState({...state, isLoading: false})
            })
            .catch(() => {
                setState({...state, isLoading: false})
            })
    };

    const validate = (values) => {
        const errors = {};
        setState(values);
        return errors;
    };

    return (
        <Card>
            <Title title="resources.tong-hop-diem-danh.name"/>
            <CardContent>
                <ReportForm params={state}
                            validate={validate}
                            excelReport={excelReport}
                            isLoading={state.isLoading}
                >
                    <Grid container spacing={8}>
                        <Grid item xs={12} sm={6}>
                            <DateInput label="resources.common.fromDate" source="fromDate"
                                       validate={required()} {...props} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <DateInput label="resources.common.toDate" source="toDate"
                                       validate={required()} {...props} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Grid item xs={12} sm={6}>
                                <label>Danh sách lớp *</label>
                                <div style={{maxHeight: '400px', overflow: 'auto'}}>
                                    <ReferenceInput source="unitIds"
                                                    reference="baseUnits"
                                                    perPage={999}
                                                    filter={{schoolYearId: schoolYears[0].id, clazzOnly: true}}
                                                    {...props} >
                                        <TreeCheckboxInput optionValue="id" optionText="name" expandTopLevel={true}/>
                                    </ReferenceInput>
                                </div>
                            </Grid>

                        </Grid>
                    </Grid>
                </ReportForm>
            </CardContent>
        </Card>
    )
}

export default TongHopDiemDanh

