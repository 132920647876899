import React, {useEffect, useState} from "react";
import axiosInstance from "../../api/axios";
import {showErrorNotify, showSuccessNotify, showWarningNotify} from "../../components";
import {connect} from 'react-redux';
import {Button, fetchEnd, fetchStart, refreshView} from 'react-admin';
import {compose} from 'redux';
import Swal from "sweetalert2";
import {change, submit} from "redux-form";

const DeclineTransferBtn = (props) => {
    const {record} = props;
    const [showBtn, setShowBtn] = useState(false);

    useEffect(() => {
        setShowBtn(record.id && record.status == 'DRAFT')
    }, [record])

    const handleClick = (e) => {
        e.stopPropagation();

        if (showBtn) {
            Swal.fire({
                title: "Bạn chắc chắn từ chối khoản thanh toán này?",
                type: 'warning',
                showCloseButton: true,
                showCancelButton: true,
                cancelButtonColor: '#d33',
                focusConfirm: false,
            })
                .then((result) => {
                    if (result.value) {
                        const url = `/api/bankTransferConsolidates/decline/${record.id}`;
                        axiosInstance.post(url)
                            .then((res) => {
                                if (res.data && res.data.success) {
                                    showSuccessNotify("TỪ chối khoản thanh toán thành công.");
                                    window.location.reload()
                                } else {
                                    showWarningNotify("Từ chối khoản thanh toán không thành công.");
                                }
                            }).catch(err => {
                            console.log(err)
                            showErrorNotify("Có lỗi xảy ra, vui lòng thử lại hoặc liên hệ quản trị viên");
                        })
                    }
                })

        }

    };

    return (
        <>
            {showBtn &&
            <Button color="primary" style={{width: 150, fontWeight: 'bold', color: '#ff4d4f'}}
                    onClick={handleClick} label={'Từ chối'}>
            </Button>
            }
        </>
    )
}


const mapStateToProps = state => ({});

const mapDispatchToProps = {
    change,
    fetchEnd,
    fetchStart,
    submit,
    refreshView
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(
    DeclineTransferBtn
);
