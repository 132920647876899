import React from 'react';
import {CardActions, Datagrid, DatagridBody, Filter, List, NumberField, TextField, TextInput} from 'react-admin';
import {DateTimeField} from "../../components";
import AcceptBtn from "./AcceptTransferBtn";
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import {connect} from "react-redux";
import {StatusField} from "./Status";
import DeclineBtn from "./DeclineTransferBtn";
import AutoConsolidateBtn from "./AutoConsolidateBtn";

const CustomDatagridRow = ({record, resource, id, onToggleItem, children, selected, basePath}) => (
    <TableRow key={id}>
        {React.Children.map(children, field => (
            <TableCell style={{width: field.props.width, padding: '4px 10px 4px 10px'}}
                       key={`${id}-${field.props.source}`}>
                <span style={{height: 'auto'}}>
                    {React.cloneElement(field, {
                        record,
                        basePath,
                        resource,
                    })}
                </span>

            </TableCell>
        ))}
    </TableRow>
);

const CustomDatagridBody = props => <DatagridBody {...props} row={<CustomDatagridRow/>}/>;
const CustomDatagrid = props => <Datagrid {...props} body={<CustomDatagridBody/>}/>;

const BillBatchFilter = (props) => (
    <div>
        <p>Dùng “<b>Mã VinaID</b>” để kiểm tra đối chiếu với sao kê và xử lý như sau: </p>
        <p style={{paddingLeft: 16}}>- Trường hợp <b>không có tiền vào</b>, nhấn nút "<b style={{color: '#ff4d4f'}}>TỪ CHỐI</b>".</p>
        <p style={{paddingLeft: 16}}>- Trường hợp <b>có tiền vào</b>: nhấn nút "<b style={{color: '#2979ff'}}>XÁC NHẬN</b>", hệ thống sẽ thực hiện nạp thẻ cho người dùng.</p>
        <Filter {...props}>
            <TextInput label="ra.action.search" source="q" alwaysOn/>
        </Filter>
    </div>
);

const PostActions = ({
                         bulkActions,
                         basePath,
                         displayedFilters,
                         filters,
                         filterValues,
                         onUnselectItems,
                         resource,
                         selectedIds,
                         showFilter,
                     }) => (
    <CardActions>
        <AutoConsolidateBtn/>
    </CardActions>
);
export const BankTransferTopupConsolidateList = ({permissions, ...props}) => (
    <List filters={<BillBatchFilter/>} {...props} actions={<PostActions/>} bulkActionButtons={false} exporter={false}>
        <CustomDatagrid>
            <TextField source="id"/>
            <TextField source="gateway"/>
            <DateTimeField source="transferDate" format="DD/MM/YYYY HH:mm:ss"/>
            <TextField source="customerId"/>
            <TextField source="customerName"/>
            <NumberField source="amount" locales="vi-VI" options={{style: 'currency', currency: 'VND'}}/>
            <TextField source="note"/>
            <StatusField source="status"/>
            <DateTimeField source="actionTime" format="HH:mm DD-MM-YYYY"/>
            <TextField source="actionBy"/>
            <AcceptBtn width={'20px'}/>
            <DeclineBtn width={'20px'}/>
        </CustomDatagrid>
    </List>
);

export default connect(null, null)(BankTransferTopupConsolidateList);
