import React from 'react';

import moment from 'moment'

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';

import {DateInput, NumberInput, required, Title} from 'react-admin';
import {downloadFile, ReportForm} from '../../components';
import env from '../../env';

class BaoCaoSuDungBienLai extends React.Component {
  state = {
    fromDate: moment().format('YYYY-MM-DD'),
    toDate: moment().format('YYYY-MM-DD'),
    fromBookNo: "",
    fromCountInBook: "",
    toBookNo: "",
    toCountInBook: "",
    isLoading: false
  };

  getReportUrl = (type) => {
    return `${env.reportURL}/reports/baoCaoChiTietBienLai?type=` + type +
        `&pFromDate=` + this.state.fromDate +
        `&pToDate=` + this.state.toDate +
        `&fromBookNo=${this.state.fromBookNo || ""}` +
        `&fromCountInBook=${this.state.fromCountInBook || ""}` +
        `&toBookNo=${this.state.toBookNo || ""}` +
        `&toCountInBook=${this.state.toCountInBook || ""}`
  }

  excelReport = () => {
    this.setState({isLoading: true})
    downloadFile(this.getReportUrl('XLS'), 'bao_cao_chi_tiet_bien_lai.xlsx').finally(() => this.setState({isLoading: false}))
  };

  validate = (values) => {
    const errors = {};
    // Validate
    if (values.fromDate && values.toDate && (values.fromDate > values.toDate) ){
      errors.toDate = 'To date must be greater than From date'
    }
    // Copy input values to params
    this.setState(values);

    return errors;
  };

  render() {
    return (
      <Card>
        <Title title="resources.baoCaoChiTietBienLai.name" />
        <CardContent>
          <ReportForm params={this.state}
            validate={this.validate}
            excelReport={this.excelReport}
                      isLoading={this.state.isLoading}
          >
            <Grid container spacing={8}>
              <Grid item xs={12} sm={6} >
                <DateInput label="resources.common.fromDate" source="fromDate" validate={required()} {...this.props} />
              </Grid>
              <Grid item xs={12} sm={6} >
                <DateInput label="resources.common.toDate" source="toDate" validate={required()} {...this.props} />
              </Grid>
            </Grid>
            <Grid container spacing={8}>
              <Grid item xs={12} sm={6}>
                <NumberInput label={"resources.inNhieuHoaDon.fromBookNo"} source="fromBookNo"/>
              </Grid>
              <Grid item xs={12} sm={6}>
                <NumberInput label={"resources.inNhieuHoaDon.fromCountInBook"} source="fromCountInBook"/>
              </Grid>
              <Grid item xs={12} sm={6}>
                <NumberInput label={"resources.inNhieuHoaDon.toBookNo"} source="toBookNo"/>
              </Grid>
              <Grid item xs={12} sm={6}>
                <NumberInput label={"resources.inNhieuHoaDon.toCountInBook"} source="toCountInBook"/>
              </Grid>
            </Grid>
          </ReportForm>
        </CardContent>
      </Card>
    )
  }
}

export default BaoCaoSuDungBienLai;

