import React from 'react';
import {SelectField} from 'react-admin';

const authStatuses = [
  {id: 'DRAFT', name: 'Đang chờ', color: ''},
  {id: 'ACCEPT', name: 'Xác nhận', color: '#2979ff'},
  {id: 'DECLINE', name: 'Từ chối', color: '#ff4d4f'},
]

export const StatusField = (props) => (
    <SelectField source={props.source}
                 choices={authStatuses}
                 {...props}
                 optionText={(e) => <span style={{color: e.color}}>{e.name}</span>}/>
)
