import React from 'react';
import {Datagrid, Filter, FunctionField, List, ReferenceField, TextField, TextInput, translate} from "react-admin";
import {DateTimeField} from "../../components";

const HistoryFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Tìm tên" source="q" alwaysOn/>
    </Filter>
);

const PaymentGatewayConfigurationHistoryList = (props) => {
    return <List filters={<HistoryFilter date={new Date()}/>}
                 bulkActions={false}
                 exporter={false}
                 perPage={25}
                 {...props}>
        <Datagrid>
            <ReferenceField source="changedObjId" reference="paymentGatewayConfigurations" allowEmpty>
                <TextField source="name"/>
            </ReferenceField>
            <FunctionField label={props.translate("resources.payment-gateway-configuration-history.fields.oldStatus")}
                           render={({oldValue}) => JSON.parse(oldValue).active ? 'Bật' : 'Tắt'}/>
            <FunctionField label={props.translate("resources.payment-gateway-configuration-history.fields.newStatus")}
                           render={({newValue}) => JSON.parse(newValue).active ? 'Bật' : 'Tắt'}/>
            <DateTimeField source="lastUpdatedOn" format="kk:mm DD/MM/YYYY" style={{width: 120}}/>
            <TextField source="lastUpdatedBy"/>
        </Datagrid>
    </List>
};

export default translate(PaymentGatewayConfigurationHistoryList)
