import * as React from 'react';
import {CardActions, Filter, List, NumberField, TextField, TextInput} from 'react-admin';
import {DateTimeField, DeleteReceiptButton, PrintPhieuThuButton, PrintReceiptButton} from '../../components'
import {BaseCustomDatagrid} from "../../components/BaseCustomDatagrid";
import ViewBillDetailButton from "../../components/ViewBillDetailButton";
import PrintConfirmPaymentButton from "../../components/PrintConfirmPaymentButton";
import PrintBienNhanButton from "../../components/PrintBienNhanButton";

const iframeStyle = {
    width: '1px', height: '1px',
    visibility: 'hidden',
};

const PostActions = () => (
    <CardActions>
        <iframe id="pdf-frame" style={iframeStyle}></iframe>
    </CardActions>
);


const PaymentViewFilter = (props) => (
    <Filter {...props}>
        <TextInput label="ra.action.search" source="q" alwaysOn/>
    </Filter>
);

export const PaymentViewList = props => (
    <List filters={<PaymentViewFilter/>} {...props} bulkActionButtons={false} actions={<PostActions/>}>
        <BaseCustomDatagrid showSelectBox={false}>
            {/*<TextField source="paymentNo" />*/}
            <TextField source="customerId" width={'5%'}/>
            <TextField source="payerName" width={'15%'}/>
            <TextField source="payerAddress" width={'4%'}/>
            <TextField source="note" width={'12%'}/>
            <NumberField source="paymentAmount" locales="vi-VI" options={{style: 'currency', currency: 'VND'}}
                         width={'7%'}/>
            <DateTimeField source="paymentDate" format="DD-MM-YYYY HH:mm" width={'7%'}/>
            <TextField source="displayPaymentMethod" label="resources.paymentMethod.name" width={'8%'}/>
            <TextField source="electronicBillNum" width={'5%'}/>
            <DateTimeField source="electronicBillDate" format="DD/MM/YYYY HH:mm:ss" width={'8%'}/>
            <TextField source="displayPhieuThu" width={'5%'}/>
            <ViewBillDetailButton/>
            <PrintReceiptButton/>
            <PrintPhieuThuButton/>
            <PrintBienNhanButton/>
            <PrintConfirmPaymentButton/>
            <DeleteReceiptButton title={'Không thể hoàn tác sau khi huỷ, Bạn chắc chắn muốn huỷ hoá đơn?'} path={'/api/paymentView'}/>
        </BaseCustomDatagrid>
    </List>
);

export default PaymentViewList
